import { Injectable } from '@angular/core';
import { retry, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient, public global: GlobalService) { }

  private contentLang() {
    return JSON.parse(localStorage.u || '{}').l || this.global.propConfs$.langs[0];
  }

  /*-- GET LIST OF CATEGORIES --*/
  public loadCategories(pSize = null, pIndex = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId,
      'pageSize': pSize,
      'pageIndex': pIndex
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/categories`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- GET A CATEGORY INFO --*/
  public loadCategoryInfo(categoryId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'categoryId': categoryId,
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/category`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- GET CONTENT FROM A CATEGORY --*/
  public loadCategoryContent(categoryId, pSize = null, pIndex = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'categoryId': categoryId,
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId,
      'pageSize': pSize,
      'pageIndex': pIndex
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/category/medias`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- GET A CONTENT INFO --*/
  public loadContentInfo(categoryId, gameId) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId,
      'categoryId': categoryId,
      'gameId': gameId
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/game`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- GET A CONTENTS FROM SEVERAL CATAGORIES --*/
  public loadContentsByCategories(categoriesList: any[] = null, pSize = null, pIndex = null, groupBy = 'CONTENT', sField = 'mDate', sDir = 'DESC', catInfo: boolean = false) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId,
      'groupBy': groupBy,
      'categories': categoriesList,
      'pageSize': pSize,
      'pageIndex': pIndex,
      'sortField': sField,
      'sortDirection': sDir,
      "categoryInfo": catInfo
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/category/content`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- GET LATEST CONTENTS FROM CATAGORIES --*/
  public loadLatestContent(categoriesList: any[] = null, contentNum = 1, groupBy = 'CONTENT') {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId,
      'groupBy': groupBy,
      'categories': categoriesList,
      'numberOfContents': contentNum
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/category/content/latest`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

  /*-- SEARCH CONTENT --*/
  public searchByKeyword(keyword) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const body = {
      'keyword': keyword,
      'channelId': this.global.propConfs$.neo[this.contentLang()].channelId,
      'viewId': this.global.propConfs$.neo[this.contentLang()].viewId,
      'catTypeId': this.global.propConfs$.neo.channelTypeId
    }

    return this.http.post<any>(`${this.global.relativeUrl}/facade/game/search`, body, httpOptions).pipe(retry(3), map((res) => {
      return res['responseData']
    }));
  }

}
