<div id="sideNav" *ngIf="showSideNav" [class.navEntrie]="animSideNav">
    <div class="closeMenu" (click)="beh.toggleSideNav(false)"></div>
    <div class="container">
        <div class="sidenav" [class.navEntrie]="animSideNav">

            <a class="sidemenu-item" *ngFor="let item of categories$ | slice :0:global.propConfs$.neo.mainCats" [routerLink]="['category',item.catId]" queryParamsHandling="merge">{{ item.catName }}</a>

            <a class="sidemenu-item sidemenu-spand" (click)="showCatList = !showCatList">
                {{ 'sideNav.categories' | translate }}
                <span class="expand-item" *ngIf="!showCatList"><i class="fas fa-chevron-right"></i></span>
                <span class="expand-item" *ngIf="showCatList"><i class="fas fa-chevron-down"></i></span>
            </a>

            <div class="sidenav-submenu" *ngIf="showCatList">
                <a *ngFor="let item of categories$ | slice :global.propConfs$.neo.mainCats" class="sidemenu-subitem" [routerLink]="['category',item.catId]" queryParamsHandling="merge">{{item.catName}}</a>
            </div>

            <a class="sidemenu-item" *ngFor="let info of infoPages$" routerLink="/info/{{info.page}}" queryParamsHandling="merge">{{ 'sideNav.'+info.page | translate }}</a>
            <a class="sidemenu-item" [routerLink]="['']" queryParamsHandling="merge" (click)="beh.toggleSideNav(false)"><i class="fa fa-home"></i> {{ 'sideNav.home' | translate }}</a>
            <a class="sidemenu-item" *ngIf="!!global.propConfs$.appDownload" [href]="global.propConfs$.appDownload" (click)="regist()" [title]="'sideNav.downloadApp' | translate" download="Game Box">
                {{ 'sideNav.app' | translate }} <img [src]="global.propConfs$.gameboxAppLogo" class="appLogo" alt="Game Box">
            </a>

        </div>
    </div>
</div>