<footer id="footer">
    <div class="footerHead py-2">
        <div class="container">{{ 'footer.gamesCategories' | translate }}</div>
    </div>
    <main class="footerBody">
        <div class="container">
            <a *ngFor="let category of categories$" [routerLink]="['category',category.catId]" queryParamsHandling="merge" [title]="category.catName" class="categories">|  {{ category.catName }}</a>

            <a *ngIf="!!global.propConfs$.appDownload" [href]="global.propConfs$.appDownload" (click)="regist()" [title]="'sideNav.downloadApp' | translate" class="categories" download="Game Box">
                | {{ 'sideNav.app' | translate }} <img [src]="global.propConfs$.gameboxAppLogo" class="appLogo" alt="App Logo">
            </a>
            <a [routerLink]="['']" queryParamsHandling="merge" title="Home" class="categories"><i class="fa fa-home"></i> {{ 'sideNav.home' | translate }}</a>
        </div>
    </main>
    <div class="footerBottom text-center py-2">
        <div class="container">{{ 'footer.copyRights' | translate: { year : dateYear } }}</div>
    </div>
</footer>