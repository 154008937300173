import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { BehaviorService } from './behavior.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigsService {

  private siteName: string = environment.production ? location.hostname : this.devSiteName();

  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private beh: BehaviorService
  ) { }

  /*---------- GET CONFs ----------*/
  public portalConf() {
    return this.http.get<any>(`${this.global.relativeUrl}/product-conf/${this.devSiteName()},playbox.global.default/object/variables/plain`, this.httpOptions).pipe(map(res => {
      if (!!res) {
        let propList = {};
        for (let prop in res) {
          if (prop.includes('Obj.')) {
            propList[prop.split('.')[1]] = this.objectConstruct(propList[prop.split('.')[1]], prop.replace(`Obj.${prop.split('.')[1]}.`, '').split('.'), res[prop]);
          } else if (prop.includes('Array.')) {
            propList[prop.replace('Array.', '')] = res[prop].split(',').map(v => this.parseValue(v));
          } else {
            propList[prop] = this.parseValue(res[prop]);
          }
        }

        this.beh.propConfs.next(propList);
        return true;
      }
      return false;
    }));
  }

  private objectConstruct(obj, property, value) {
    obj = obj || {};
    const setValue = (index: number) => { return index >= (property.length - 1) ? this.parseValue(value) : {} }

    for (let index in property) {
      if (+index == 0 && !obj[property[index]]) {
        obj[property[index]] = setValue(+index);
      } else if (+index == 1 && !obj[property[0]][property[index]]) {
        obj[property[0]][property[index]] = setValue(+index);
      } else if (+index == 2 && !obj[property[0]][property[1]][property[index]]) {
        obj[property[0]][property[1]][property[index]] = setValue(+index);
      } else if (+index == 3 && !obj[property[0]][property[1]][property[2]][property[index]]) {
        obj[property[0]][property[1]][property[2]][property[index]] = setValue(+index);
      } else if (+index == 4 && !obj[property[0]][property[1]][property[2]][property[3]][property[index]]) {
        obj[property[0]][property[1]][property[2]][property[3]][property[index]] = setValue(+index);
      }
    }

    return obj;
  }

  private parseValue(value: any) {
    return value == 'null' || value == 'true' || value == 'false' || !isNaN(value) ? JSON.parse(value) : value;
  }

  private devSiteName() {
    let params = new URLSearchParams(location.search);
    if (params.has('portal')) {
      localStorage.p = params.get('portal');
      return params.get('portal');
    } else if (localStorage.p) {
      return localStorage.p;
    } else {
      return location.hostname;
    }
  }
}
