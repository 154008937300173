import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import { map } from 'rxjs/operators';
import { BaseRequestBody } from '../_models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class OptinService {

  constructor(
    private http: HttpClient,
    private main: MainService
  ) { }

  public getUser(params: BaseRequestBody, useJwt) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/optin`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders(useJwt) }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

}