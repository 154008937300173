import { Component, AfterViewInit } from '@angular/core';
import { EventsService } from "src/app/_core/_services/events.service";

@Component({
  selector: 'app-pwa-prompt-events',
  template: `
    <div *ngIf="showPrompt" id="pwa-prompt">
      <img (click)="userChoiceClick()" src="./../../../assets/icons/icon-128x128.png" alt="Logo Playbox">
      <p (click)="userChoiceClick()">{{ 'addToHomeScreen' | translate: { name: "Playbox"} }}</p>
      <span (click)="closeMiniBar()"><i class="fas fa-times"></i></span>
    </div>
  `,
  styles: [`
    @keyframes promptSlideIn {
      0% { bottom: -50%;}
      100% { bottom: 0;}
    }
    
    #pwa-prompt {
      background-color: var(--neutColor1);
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 450px;
      display: flex;
      align-items: center;
      z-index: 10;
      padding: .75rem 1.25rem;
      box-shadow: 0 0 5px var(--neutColor2-25);
      animation: promptSlideIn .5s ease-in .5s;
    }

    #pwa-prompt img { height: 2.5rem; }

    #pwa-prompt p {
      margin: 0;
      margin-right: auto;
      padding: 0 .5rem;
      line-height: 1;
    }
  `]
})
export class PwaPromptEventsComponent implements AfterViewInit {

  public showPrompt: boolean = false;
  private deferredPrompt;

  constructor(
    private events: EventsService
  ) { }

  ngAfterViewInit() {
    location.protocol == 'https:' && !sessionStorage.getItem('PwaP') && this.checkAppLaunch();
  }

  /*-- PORTAL OPENED ON DEVICE HOME SCREEN --*/
  private checkAppLaunch() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.events.eventStart({ eventType: 'ADD_TO_HOME_SCREEN_CLICK', eventCategory: 'PWA' });
    } else {
      this.checkPromptShowed();
    }
  }

  /*-- SHOW MINI-INFO BAR --*/
  private checkPromptShowed() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.checkAppInstall();
      if (!sessionStorage.getItem('PwaP')) {
        this.showPrompt = true;
        this.events.eventStart({ eventType: 'ADD_TO_HOME_SCREEN_SHOW', eventCategory: 'PWA' });
      }
    });
  }

  /*-- SEE IF PORTAL INSTALLED SUCCESSFULLY --*/
  private checkAppInstall() {
    window.addEventListener('appinstalled', (evt) => {
      this.events.eventStart({ eventType: 'ADD_TO_HOME_SCREEN_SUCCESS', eventCategory: 'PWA' });
    });
  }

  /*-- CATCH USER CHOICE - (NOT)ACCEPTED --*/
  public userChoiceClick() {
    this.showPrompt = false;
    this.deferredPrompt.prompt();

    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        sessionStorage.setItem('PwaP', 'accepted');
        this.events.eventStart({ eventType: 'ADD_TO_HOME_SCREEN_ACCEPTED', eventCategory: 'PWA' });
      } else {
        sessionStorage.setItem('PwaP', 'dismissed');
        this.events.eventStart({ eventType: 'ADD_TO_HOME_SCREEN_REFUSED', eventCategory: 'PWA' });
      }
    })
  }

  /*-- CLOSE MINI-INFO BAR --*/
  public closeMiniBar() {
    this.showPrompt = false;
    sessionStorage.setItem('PwaP', 'closed');
  }

}
