import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { UUID } from "angular2-uuid";
import { BaseRequestBody } from '../_models/auth.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public readonly relativeUrl: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  constructor() { }

  private siteName() {
    let params = new URLSearchParams(location.search);
    if (params.has('portal')) {
      localStorage.p = params.get('portal');
      return params.get('portal');
    } else if (localStorage.p) {
      return localStorage.p;
    } else {
      return location.hostname;
    }
  }

  public userStorage() {
    return localStorage.u ? JSON.parse(localStorage.u) : {};
  }

  public callHeaders(useJwt: boolean = true) {
    let httpOptions = new HttpHeaders().set('Content-Type', 'application/json');
    useJwt && this.userStorage().j && (httpOptions = httpOptions.set('jwt', this.userStorage().j));
    return httpOptions;
  }

  public requestPreset() {
    let requestBody: BaseRequestBody = {
      connectionType: this._connection() ? this._connection().effectiveType : undefined,
      lang: this.userStorage().l || undefined,
      deviceUuid: this.userStorage().d || undefined,
      userAgent: navigator.userAgent,
      referer: environment.production ? location.hostname : this.siteName(),
      url: environment.production ? location.href : this.siteName(),
      timestamp: new Date().getTime(),
      transactionId: UUID.UUID(),
      context: {},
      additionalParameters: {}
    }

    return requestBody;
  }

  public mapResponse(response) {
    return !response.inError && response.responseCode == '200' ? { ...response.responseData, ...{ additionalFields: response.additionalFields } } : null;
  }

  private _connection() {
    return navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'] || undefined
  }
}
