<div *ngIf="propsLoaded$" id="appBase">
  <app-header></app-header>
  <app-side-nav></app-side-nav>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-user-popup></app-user-popup>
  <app-pwa-prompt-events></app-pwa-prompt-events>
</div>

<div *ngIf="propsLoaded$ === null" class="mainLoader">
  <div>
    <div class="contentLoader"><span></span></div>
    <h6>{{ 'Loading...' }}</h6>
  </div>
</div>

<div *ngIf="propsLoaded$ === false" class="networkError">
  <div>
    <span class="icons"><i class="fas fa-exclamation-circle"></i></span>
    <h6 [innerHTML]="'<b>Oops!</b>\n Something went wrong.\n Please try again later!'"></h6>
  </div>
</div>