import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import { BaseRequestBody } from '../_models/auth.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private main: MainService
  ) { }

  public checkSubscription(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/subscription`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public doSubscribe(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/subscription/subscribe`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public doUnsubscribe(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/subscription/unsubscribe`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }
}
