import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import { BaseRequestBody, SuggestRequestBody, AddFriendRequestBody } from '../_models/auth.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private main: MainService
  ) { }

  public insertUpdate(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/insert-or-update`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public friendsList() {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/friends`, this.main.requestPreset(), { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public friendsSuggestions(params: SuggestRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/friends/suggestions`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public inviteFriend(params: SuggestRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/friends/invite`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public addFriend(params: AddFriendRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/friends/add`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public doLogin(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/login`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  public doPinCheck(params: BaseRequestBody) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/login/confirm`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  }

  /* public doRecoverPass(params) {
    return this.http.post<any>(`${this.main.relativeUrl}/auth-twt/account/login/confirm`, { ...params, ...this.main.requestPreset() }, { headers: this.main.callHeaders() }).pipe(map(res => {
      return this.main.mapResponse(res);
    }));
  } */
}
