import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorService } from 'src/app/_core/_services/behavior.service';
import { GlobalService } from 'src/app/_core/global.service';
import { LoginService } from 'src/app/_core/_services/login.service';
// import { SubscriptionService } from 'src/app/_core/_services/subscription.service';
import { EventsService } from 'src/app/_core/_services/events.service';
import { Authentication } from 'projects/authentication/auth.core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnInit {

  public showPopUp: boolean = false;
  public showCont: boolean = false;
  public showBox: boolean = false;
  public popUp: any = {
    pushNotif: false,
    insertMsisdn: false,
    insertPin: false,
    profile: false,
    subscribe: false,
    subscribeSuccess: false,
    subscribeError: false,
    noCredit: false
  };

  public incorrectMsisdn: boolean = false;
  public incorrectPin: boolean = false;
  public pendingCall: boolean = false;

  private normalizedMsisdn: string;
  public userMsisdn: string;
  public isUserSubscribed: boolean = null;
  private userSubscription: any;
  public portalClubs$: any[];

  private apiRequest$: string;

  @ViewChild('selectClub') private clubId: ElementRef;

  constructor(
    private router: Router,
    public beh: BehaviorService,
    public global: GlobalService,
    private loginSvc: LoginService,
    // private subcriptionService: SubscriptionService,
    private auth: Authentication,
    private events: EventsService
  ) {
    this.beh._userPopUp.subscribe(val => {
      val ? this.choosePopUp(val) : this.choosePopUp();

      switch (val) {
        case 'insertMsisdn':
          this.events.eventStart({ eventType: 'MSISDN_PAGE_ACCESS', eventCategory: 'ACCESS' });
          break;
        case 'insertPin':
          this.events.eventStart({ eventType: 'PIN_PAGE_ACCESS', eventCategory: 'ACCESS' });
          break;
        case 'profile':
          this.events.eventStart({ eventType: 'MY_PROFILE_PAGE_ACCESS', eventCategory: 'ACCESS' });

          this.userMsisdn = JSON.parse(localStorage.u || '{}').m ? atob(JSON.parse(localStorage.u).m) : null;
          this.global.propConfs$.subscriptionNeeded && this.subsCheck();
          break;
      }
    });

    this.beh.portalClubs.subscribe(clubs => { this.portalClubs$ = clubs });

    this.beh.apiRequest.subscribe(val => { this.apiRequest$ = val });
  }

  ngOnInit() { }

  public choosePopUp(action = null) {
    setTimeout(() => {
      this.showBox = false;

      if (action) {
        for (let pop in this.popUp) { this.popUp[pop] = false };
        this.showPopUp = true;
        this.popUp[action] = true;
        setTimeout(() => {
          this.showBox = true;
          this.showCont = true;
          document.querySelector('body').classList.add('modal-open');
        }, 200);
      } else {
        this.showCont = false;
        setTimeout(() => {
          for (let pop in this.popUp) { this.popUp[pop] = false };
          this.showPopUp = false;
          this.showBox = false;
          document.querySelector('body').classList.remove('modal-open');
          this.incorrectMsisdn = false;
          this.incorrectPin = false;
          this.pendingCall = false;
        }, 200);
      }
    }, 0);
  }

  /*---------- SUBSCRIPTION ----------*/
  private subsCheck() {
    this.beh.userSubscription.subscribe(userSubs => {
      this.userSubscription = userSubs;

      if (!this.userSubscription) {
        this.auth.subscription({ status: ['ACTIVE'] }).subscribe(sub => {
          if (sub?.responseCode == 'SUCCESS' && sub.subscriptions.length >= 1) {
            for (let subscription of sub.subscriptions) {
              if (subscription.status == 'ACTIVE' && (this.global.propConfs$['sub.access.notbillet'] || subscription.billed) || (this.global.propConfs$['sub.access.grace'] && subscription.grace)) {
                this.beh.userSubscription.next(subscription);
                this.isUserSubscribed = true;
                return true;
              }
            }
            this.isUserSubscribed = false;
          } else { this.isUserSubscribed = false }
        });
      } else {
        if (
          this.userSubscription.status == 'ACTIVE' &&
          (this.global.propConfs$['sub.access.notbillet'] || this.userSubscription.billed) ||
          (this.global.propConfs$['sub.access.grace'] && this.userSubscription.grace)
        ) { this.isUserSubscribed = true } else { this.isUserSubscribed = false }
      }
    });

    /* this.subcriptionService.subscriptionCheck().subscribe(subs => {
      this.isUserSubscribed = subs.subStatus == 1 ? true : false;
    }); */
  }

  public subscribe() {
    this.events.eventStart({ eventType: 'SUBSCRIPTION_CTA_CLICK', eventCategory: 'CLICK' });

    if (!!!this.global.propConfs$.outsideSubscription) {
      this.events.eventStart({ eventType: 'SUBSCRIPTION', eventCategory: 'SUBMIT' });

      this.pendingCall = true;

      this.auth.subscribe({ productId: +this.clubId.nativeElement.value || 0 }).subscribe(sub => {
        this.pendingCall = false;
        if (sub?.responseCode == 'SUCCESS') {
          for (let subscrip of sub.subscriptions) {
            if (subscrip.status == 'ACTIVE' && (this.global.propConfs$['sub.access.notbillet'] || subscrip.billed) || (this.global.propConfs$['sub.access.grace'] && subscrip.grace)) {
              this.beh.userSubscription.next(subscrip);
              this.beh._userPopUp.next('subscribeSuccess');
              this.events.eventStart({ eventType: 'SUBSCRIPTION_SUCCESS', eventCategory: 'SUCCESS', requestData: this.apiRequest$, responseData: JSON.stringify(sub) });
              return true;
            }
          }

          this.beh._userPopUp.next('noCredit');
          this.events.eventStart({ eventType: 'SUBSCRIPTION_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: JSON.stringify(sub) });
        } else {
          this.beh._userPopUp.next('subscribeError');
          this.events.eventStart({ eventType: 'SUBSCRIPTION_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: JSON.stringify(sub) });
        }
      }, noSub => {
        this.pendingCall = false;
        this.beh._userPopUp.next('subscribeError');
        this.events.eventStart({ eventType: 'SUBSCRIPTION_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: JSON.stringify(noSub) });
      });

    } else {
      this.events.eventStart({ eventType: 'USER_REDIRECT', eventCategory: 'REDIRECT', redirectUrl: this.global.propConfs$.outsideSubscription });
    }
  }

  public unsubscribe() {
    this.events.eventStart({ eventType: 'UNSUBSCRIPTION', eventCategory: 'SUBMIT' });

    this.pendingCall = true;

    this.auth.unsubscribe({ subId: this.userSubscription.id || 0 }).subscribe(unsub => {
      if (unsub?.responseCode == 'SUCCESS') {
        this.beh._userPopUp.next('subscribeSuccess');
        this.events.eventStart({ eventType: 'UNSUBSCRIPTION_SUCCESS', eventCategory: 'SUCCESS', requestData: this.apiRequest$, responseData: JSON.stringify(unsub) });
        setTimeout(() => { window.location.reload() }, 2000);
      } else {
        this.beh._userPopUp.next('subscribeError');
        this.events.eventStart({ eventType: 'UNSUBSCRIPTION_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: JSON.stringify(unsub) });
      }
      this.pendingCall = false;
    }, noUnsub => {
      this.beh._userPopUp.next('subscribeError');
      this.pendingCall = false;
      this.events.eventStart({ eventType: 'UNSUBSCRIPTION_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: JSON.stringify(noUnsub) });
    });
  }
  /*---------- SUBSCRIPTION ----------*/

  /*---------- AUTHENTICATION ----------*/
  public logout() {
    this.beh._userPopUp.next(null);
    this.loginSvc.doLogOut();
  }

  public requestNewPin(msisdn) {
    this.events.eventStart({ eventType: 'MSISDN_SUBMITTED', eventCategory: 'SUBMIT' });

    if (!!msisdn) {
      this.pendingCall = true;

      this.auth.login({ accounts: [{ identityType: 'MSISDN', identity: msisdn, msisdn: msisdn }] }).subscribe(logIn => {
        if (logIn?.responseCode == 'SUCCESS') {
          this.normalizedMsisdn = logIn.msisdn;

          this.pendingCall = false;
          if (this.global.propConfs$.pinConfirmation) {
            this.beh._userPopUp.next('insertPin');
            this.global.propConfs$.pinConfirmation && setTimeout(() => { document.querySelector<HTMLInputElement>('.insertPin input').focus() }, 0);
          } else {
            this.events.eventStart({ eventType: 'LOGIN', eventCategory: 'ACCESS' });
            this.loginSvc.doLogin(this.normalizedMsisdn, true);
          }

          this.events.eventStart({ eventType: 'MSISDN_SUCCESS', eventCategory: 'SUCCESS', requestData: this.apiRequest$, responseData: JSON.stringify(logIn) });
        } else { this.msisdNotValid(JSON.stringify(logIn)) }
      }, noLogIn => { this.msisdNotValid(JSON.stringify(noLogIn)) });

    } else {
      this.events.eventStart({ eventType: 'MSISDN_EMPTY', eventCategory: 'ERROR' });
    }
  }

  public pinCheck(pin) {
    this.events.eventStart({ eventType: 'PIN_SUBMITED', eventCategory: 'SUBMIT' });

    if (!!pin) {
      this.pendingCall = true;

      this.auth.validatePin({ msisdn: this.normalizedMsisdn, pinCode: pin }).subscribe(validate => {
        if (validate?.responseCode == 'SUCCESS') {
          this.events.eventStart({ eventType: 'PIN_SUBMIT_SUCCESS', eventCategory: 'SUCCESS', requestData: this.apiRequest$, responseData: JSON.stringify(validate) });

          let userSession = sessionStorage.u ? JSON.parse(sessionStorage.u) : {};
          userSession.tt = 2;
          sessionStorage.u = JSON.stringify(userSession);

          this.pendingCall = false;
          this.events.eventStart({ eventType: 'LOGIN', eventCategory: 'ACCESS' });
          this.loginSvc.doLogin(this.normalizedMsisdn, true);
        } else { this.pinNotValid(JSON.stringify(validate)) }
      }, noValidate => { this.pinNotValid(JSON.stringify(noValidate)) });

    } else {
      this.events.eventStart({ eventType: 'PIN_EMPTY', eventCategory: 'ERROR' });
    }
  }

  private msisdNotValid(response) {
    this.incorrectMsisdn = true;
    this.pendingCall = false;

    this.events.eventStart({ eventType: 'MSISDN_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: response });
  }

  private pinNotValid(response) {
    this.incorrectPin = true;
    this.pendingCall = false;

    this.events.eventStart({ eventType: 'PIN_SUBMIT_ERROR', eventCategory: 'ERROR', requestData: this.apiRequest$, responseData: response });
  }
  /*---------- AUTHENTICATION ----------*/

}
