import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorService } from '../_services/behavior.service';
import { EventsService } from '../_services/events.service';
import { GlobalService } from '../global.service';

@Injectable()
export class ApiCallsInterceptor implements HttpInterceptor {

  constructor(
    private beh: BehaviorService,
    private event: EventsService,
    private global: GlobalService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    !request.url.includes('/event/') && this.eventCall('API_CALL', request.url.split('?')[0].split('/').reverse()[0], { req: JSON.stringify(request) });

    return next.handle(request).pipe(tap<any>(response => {
      if (response.headers && response.headers.has('user-token')) {
        let user = JSON.parse(localStorage.u || '{}');
        user.j = response.headers.get('user-token');
        localStorage.u = JSON.stringify(user);
      }
      response.url && !response.url.includes('/event/') && this.eventCall('API_CALL_SUCCESS', request.url.split('?')[0].split('/').reverse()[0], { req: JSON.stringify(request), res: JSON.stringify(response), code: response.status });
    }, noResponse => {
      // !noResponse.url.includes('/event/') && this.beh.popup.next('error');
      noResponse.url && !noResponse.url.includes('/event/') && this.eventCall('API_CALL_ERROR', request.url.split('?')[0].split('/').reverse()[0], { req: JSON.stringify(request), res: JSON.stringify(noResponse), code: noResponse.status });
    }), catchError(this.handleError));
  }

  /*---------- ERROR HANDLE ----------*/
  private handleError(error: HttpErrorResponse) {
    let errResp: any = error;
    delete errResp.headers;
    return throwError(errResp);
  }

  private eventCall(type: 'API_CALL' | 'API_CALL_SUCCESS' | 'API_CALL_ERROR', subType: string, call: { req?, res?, code?}) {
    this.global.propConfs$ && this.event.eventStart({ eventType: type, eventSubType: subType, eventCategory: type.includes('SUCCESS') ? 'SUCCESS' : type.includes('ERROR') ? 'ERROR' : 'API', requestData: call.req || undefined, responseData: call.res || undefined, responseCode: call.code || undefined });
  }
}