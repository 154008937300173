import { Component, OnInit } from '@angular/core';

import { BehaviorService } from "src/app/_core/_services/behavior.service";
import { GlobalService } from 'src/app/_core/global.service';
import { EventsService } from 'src/app/_core/_services/events.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  showCatList: boolean = false;
  showSideNav: boolean = false;
  animSideNav: boolean = false;
  categories$: any;
  infoPages$: any;

  constructor(
    public beh: BehaviorService,
    public global: GlobalService,
    private events: EventsService
  ) {
    this.beh._categories.subscribe(cat => { this.categories$ = cat });
    this.beh._sideNav.subscribe(val => {
      if (val == true) {
        this.showSideNav = true;
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        setTimeout(() => {
          this.animSideNav = true;
          document.querySelector('body').classList.add('modal-open');
        }, 0);
      } else if (val == false) {
        this.animSideNav = false;
        setTimeout(() => {
          this.showSideNav = false;
          document.querySelector('body').classList.remove('modal-open');
        }, 500);
      }
    });

    this.mapInfoPages();
  }

  ngOnInit() {
  }

  public regist() {
    this.events.eventStart({ eventType: 'APP_DOWNLOAD', eventCategory: 'CLICK', downloadUrl: this.global.propConfs$.appDownload });
  }

  private langSelected() {
    return JSON.parse(localStorage.u || '{}').l || this.global.propConfs$.langs[0];
  }

  private mapInfoPages() {
    this.infoPages$ = [];
    if (this.global.propConfs$.infoPages) {
      for (let page in this.global.propConfs$.infoPages[this.langSelected()]) {
        this.global.propConfs$.infoPages[this.langSelected()][page] && this.infoPages$.push({ 'page': page });
      }
    }
  }

}
