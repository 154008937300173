import { Component, OnInit } from '@angular/core';

import { ContentService } from "src/app/_core/_services/content.service";
import { BehaviorService } from "src/app/_core/_services/behavior.service";
import { GlobalService } from 'src/app/_core/global.service';
import { EventsService } from 'src/app/_core/_services/events.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public categories$: any;
  public dateYear: number = new Date().getFullYear();

  constructor(
    private beh: BehaviorService,
    private neo: ContentService,
    public global: GlobalService,
    private events: EventsService
  ) {
    this.neo.loadCategories().subscribe(cat => {
      this.beh._categories.next(cat.result);
      this.categories$ = cat.result;
    })
  }

  ngOnInit() {
  }

  public regist() {
    this.events.eventStart({ eventType: 'APP_DOWNLOAD', eventCategory: 'CLICK', downloadUrl: this.global.propConfs$.appDownload });
  }

}
