import { Injectable } from '@angular/core';
import { OptinService } from './_services/optin.service';
import { AccountService } from './_services/account.service';
import { Account, SuggestRequestBody, AddFriendRequestBody } from './_models/auth.model';
import { SubscriptionService } from './_services/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class Authentication {

  constructor(
    private optinSvc: OptinService,
    private accountSvc: AccountService,
    private subSvc: SubscriptionService
  ) { }

  public optin(params: { msisdnToken?: string } = {}, logged: boolean = true) {
    return this.optinSvc.getUser(params, logged);
  }

  public updateUser(params: { accounts: Array<Account>, friends?: Array<Account> }) {
    return this.accountSvc.insertUpdate(params);
  }

  public friends() {
    return this.accountSvc.friendsList();
  }

  public suggestFriend(params: SuggestRequestBody) {
    return this.accountSvc.friendsSuggestions(params);
  }

  public newFriend(params: AddFriendRequestBody) {
    return this.accountSvc.addFriend(params);
  }

  public invite(params: SuggestRequestBody) {
    return this.accountSvc.inviteFriend(params);
  }

  public login(params: { accounts: Array<Account> }) {
    return this.accountSvc.doLogin(params);
  }

  public validatePin(params: { pinCode: string, msisdn: string }) {
    return this.accountSvc.doPinCheck(params);
  }

  public subscription(params: { status: Array<'ACTIVE'> }) {
    return this.subSvc.checkSubscription(params);
  }

  public subscribe(params: { productId: number }) {
    return this.subSvc.doSubscribe(params);
  }

  public unsubscribe(params: { subId: number }) {
    return this.subSvc.doUnsubscribe(params);
  }
}