import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { LangService } from "src/app/_core/_services/lang.service";
import { BehaviorService } from 'src/app/_core/_services/behavior.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/_core/global.service';
import { LoginService } from 'src/app/_core/_services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public loggedIn: boolean = false;
  public inHomePage: boolean;
  public sideNavOpen: boolean;
  public isOnTop: boolean = true;
  public doSearch: boolean = false;
  public showScroll: boolean = false;

  public lang$: string;

  @ViewChild('searchInput') searchField: ElementRef;

  constructor(
    public langService: LangService,
    public beh: BehaviorService,
    private router: Router,
    public global: GlobalService,
    private loginService: LoginService
  ) {
    this.beh.isInHome.subscribe(val => { this.inHomePage = val });
    this.beh._sideNav.subscribe(val => { this.sideNavOpen = val });

    this.langService.lang.subscribe(lang => { this.lang$ = lang });
    this.loginService.currentUser.subscribe(user => {
      this.loggedIn =  user?.m ? true : false;
    });
  }

  ngOnInit() {
    window.addEventListener('scroll', () => {
      let endSlideLimit = window.innerWidth < 768 ? 205 : 355;
      this.isOnTop = window.scrollY > endSlideLimit ? false : true;
      this.showScroll = (this.inHomePage && window.scrollY > endSlideLimit) || (!this.inHomePage && window.scrollY > 75) ? true : false;
    });
  }

  public search() {
    if (this.doSearch) {
      if (!!this.searchField.nativeElement.value) {
        this.router.navigate([`search/${this.searchField.nativeElement.value}`], { queryParamsHandling: 'merge' });
        this.searchField.nativeElement.value = '';
      }
    } else {
      this.doSearch = true;
      this.searchField.nativeElement.focus();
    }
  }

  public scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
}