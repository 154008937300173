import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CallInterceptor } from 'projects/authentication/_interceptors/call.interceptor';
import { ApiCallsInterceptor } from 'src/app/_core/_interceptors/api-calls.interceptor';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CallInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCallsInterceptor, multi: true }
  ]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, `/product-conf/${devSiteName()},playbox.global.default/object/langs.`, '/plain');
}

function devSiteName() {
  let params = new URLSearchParams(location.search);
  if (params.has('portal')) {
    localStorage.p = params.get('portal');
    return params.get('portal');
  } else if (localStorage.p) {
    return localStorage.p;
  } else {
    return location.hostname;
  }
}